<template>
  <div class="p-home">
    <!-- <div>
      <img class="banner-img" src="../../assets/img/banner.png" alt="" />
    </div> -->
    <div class="banner">
      {{ userInfo }}
      <!-- <div class="ban-title">成人高考宝典APP</div>
      <div class="ban-subtitle">让学习成为习惯</div>
      <div class="ban-code">
        <img class="image" src="@/assets/img/qrcode.png" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
import page_mx from "@/mixin/page_mx";
// import { mapState } from "vuex";
// import store from "../../store";
export default {
  mixins: [page_mx],
  data() {
    return {};
  },
  // computed: mapState({
  //   userInfo: (state) => state.main.userInfo,
  // }),
  methods: {
    // 初始化
    async init() {
      // this.$api.ums.user.user_signInfo.get().then((res) => {
      //   console.log(res);
      // });
      // console.log(this.userInfo);
      console.log(this.isUserLogin);
      this.$api.ems.education.teachingProgram_listCourseForCurrentTerm
        .get({
          currentTerm: this.userInfo.currentTerm,
          majorId: this.userInfo.majorId,
          rollGrade: this.userInfo.rollGrade,
        })
        .then((res) => {
          this.courseList = res.data;
          this.$api.cms.resource.knowledge_listAdultEducationKnowledgeForClient
            .get({
              courseId: res.data[0].courseId,
            })
            .then((res) => {
              this.konwledgeList = res.data;
            });
        });
    },
  },
};
</script>
<style lang="less" scope>
.clear:after {
  content: "";
  height: 0;
  display: block;
  clear: both;
}

.banner-img {
  width: 100%;
}

.banner {
  height: 460px;
  width: 1160px;
  margin-left: auto;
  margin-right: auto;
  // background-image: url(~@/assets/img/detail.png);
  background-repeat: no-repeat;
  background-position: right;
  .ban-title {
    font-size: 60px;
    color: #333;
    margin-top: 60px;
  }

  .ban-subtitle {
    color: #666;
    font-size: 24px;
    margin-top: 30px;
  }

  .ban-code {
    width: 156px;
    height: 156px;
    cursor: pointer;
    .image {
      margin-top: 40px;
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
    .image:hover {
      box-shadow: 0px 0px 8px 0px rgba(121, 174, 149, 0.4);
    }
  }
}
</style>
